import axios from "axios";
import { api } from "../../utils/api";
import { request } from "../../utils/request";
import { Link, Navigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { LocksmithDetailType, LocksmithFieldType } from "../../utils/types";
import Spinner from "../../components/spinner";
import LocksmithForm from "../../components/locksmith-form";

export default function EditLocksmith() {
    const params = useParams();
    const id = Number(params.id);
    const isValidId = !Number.isNaN(id)
    const [detail, setDetail] = useState<LocksmithFieldType>();
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        if (!isValidId) return;
        setLoading(true)
        fetchDetails(id).then((data: LocksmithDetailType) => {
            setLoading(false)
            if (!data) return;
            const editableLocksmithDetail: LocksmithFieldType = {
                contact_number: data.locksmith.contact_number,
                email: data.email,
                full_name: data.full_name,
                password: ''
            }
            setDetail(editableLocksmithDetail)
        })
    }, [id, isValidId]);

    return <div className="w-full md:p-0 p-3">
        <div className="flex w-full items-center justify-between">
            <h1 className={`text-2xl`}>Edit Locksmith</h1>
            {loading && <div className="absolute w-96 h-96 items-center justify-center flex"><Spinner /></div>}
        </div>
        <div className="mt-5 pl-5 pr-5">
            {!isValidId ? <Navigate to={'/404'} replace />
                : (!loading && !detail)
                    ? <LocksmithNotFound />
                    : <LocksmithForm id={id} loading={loading} defaultValues={detail} />}
        </div>
    </div>
}


const fetchDetails = async (id: number) => {
    try {
        const response = await request('GET', api.locksmith.detail + id);
        return response.data.data
    } catch (error) {
        if (axios.isAxiosError(error)) {
            if (error.status !== 400) throw error;
        }
    }
}
function LocksmithNotFound() {
    return <main className="grid min-h-full place-items-center bg-white px-6 py-24 sm:py-32 lg:px-8">
        <div className="text-center">
            <p className="text-base font-semibold text-blue-600">Oops!</p>
            <h1 className="mt-4 text-3xl font-bold tracking-tight text-gray-900 sm:text-5xl">Locksmith not found</h1>
            <p className="mt-6 text-base leading-7 text-gray-600">Sorry, we couldn’t find the locksmith you’re looking for.</p>
            <div className="mt-10 flex items-center justify-center gap-x-6">
                <Link
                    to="/locksmiths"
                    className="rounded-md bg-blue-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
                >
                    Go back
                </Link>
            </div>
        </div>
    </main>
}
