import clsx from "clsx";
import { Field, Form, Formik } from "formik";
import { useMemo } from "react";
import * as Yup from 'yup';
import { LoginFormFieldType } from "../utils/types";
import { signIn } from "../services/auth";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Spinner from "./spinner";
import { RoleType } from "../utils/enum";

export default function LoginForm() {
    const initialValues: LoginFormFieldType = useMemo(() => ({ email: '', password: '' }), []);
    const navigate = useNavigate();

    return <div>
        <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={async (values, actions) => {
                const data = await signIn(values);
                if (data.isOk) {
                    if (data.data.role !== RoleType.ADMIN) toast('Forbidden!', { type: 'error' });
                    else {
                        navigate('/');

                    }
                }
                else toast(data.message, { type: 'error' });
                actions.setSubmitting(false)
            }}
        >
            {(helpers) => (
                <Form className="space-y-3">
                    <label htmlFor="email" className={
                        clsx("text-sm font-medium leading-6",
                            { 'text-red-600': helpers.errors.email },
                            { 'text-gray-900': !helpers.errors.email })
                    }>
                        Email address<label className="text-sm font-large leading-6 text-red-500">{helpers.errors.email && '*'}</label>
                    </label>
                    <Field
                        className="block w-full px-2 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                        type="email" name="email" />

                    <label htmlFor="password" className={
                        clsx("block text-sm font-medium leading-6",
                            { 'text-red-600': helpers.errors.email },
                            { 'text-gray-900': !helpers.errors.email })
                    }>
                        Password<label className="text-sm font-medium leading-6 text-red-500">{helpers.errors.email && '*'}</label>
                    </label>
                    <Field
                        className="block w-full px-2 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                        type="password" name="password" />
                    {
                        !helpers.isSubmitting ?
                            <button
                                className="mt-10 flex w-full justify-center rounded-md bg-blue-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
                                type="submit" disabled={helpers.isSubmitting}>
                                Submit
                            </button> : <div className="flex justify-center items-center">
                                <Spinner />
                            </div>
                    }
                </Form>
            )}
        </Formik>
    </div>
}


const validationSchema = Yup.object().shape({
    email: Yup.string().required('Required!'),
    password: Yup.string().required('Required')
})