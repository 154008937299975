import { MagnifyingGlassIcon } from '@heroicons/react/24/outline';
import { useDebouncedCallback } from 'use-debounce';

interface SearchProps {
    placeholder: string
    onChange?: (text: string) => void
    defaultValue?: string
}

export default function Search(props: SearchProps) {
    const handleSearch = useDebouncedCallback((text: any) => props.onChange?.(text), 300);
    return (
        <div className="relative flex flex-1 flex-shrink-0">
            <label htmlFor="search" className="sr-only">
                Search
            </label>
            <input
                className="peer block w-full rounded-md border border-gray-200 py-[9px] pl-10 text-sm outline-2 placeholder:text-gray-500"
                placeholder={props.placeholder}
                onChange={(e) => {
                    handleSearch(e.target.value);
                }}
                defaultValue={props.defaultValue}
            />
            <MagnifyingGlassIcon className="absolute left-3 top-1/2 h-[18px] w-[18px] -translate-y-1/2 text-gray-500 peer-focus:text-gray-900" />
        </div>
    );
}
