import moment from "moment";
import { LocksmithList } from "../utils/types";
import IconButton from "./icon-button";
import { useNavigate } from "react-router-dom";
import { PencilIcon, TrashIcon } from "@heroicons/react/24/outline";
import parsePhoneNumberFromString from "libphonenumber-js";

interface LocksmithTableMobileProps {
    data: LocksmithList[];
    loading?: boolean;
    onPressDelete?: (productId: number) => void
    onPressRow?: (e: React.MouseEvent<any, MouseEvent>, row: LocksmithList) => void
}

export default function LocksmithTableMobile(props: LocksmithTableMobileProps) {
    const navigate = useNavigate();
    return <>
        {props.data.map((item, index) => <>
            <div
                key={item.id}
                className="cursor-pointer mb-2 w-full rounded-md bg-white p-4"
                onClick={(e) => props.onPressRow?.(e, item)}
            >
                <div className="flex items-center justify-between border-b pb-4">
                    <div>
                        <div className="mb-2 flex items-center">
                            {/* <img className="w-14 h-14 object-contain mt-1 mb-1" src={item.imageUrl[0]} alt="Product" /> */}
                            <p className="text-xl font-medium">{item.full_name}</p>
                        </div>
                        <p className="text-sm text-gray-500">{item.email}</p>
                    </div>
                </div>
                <div className="flex w-full items-center justify-between pt-4">
                    <div>
                        <p>{parsePhoneNumberFromString(item.locksmith.contact_number)?.formatInternational()}</p>
                        <p className="text-sm text-gray-500">Created at {moment(item.created_at).format('ll')}</p>
                    </div>
                    <div className="flex justify-center gap-3">
                        <button onClick={(e) => {
                            e.stopPropagation();
                            navigate('/locksmiths/edit/' + item.id)
                        }}>
                            <IconButton icon={PencilIcon} />
                        </button>
                        <button onClick={(e) => {
                            e.stopPropagation()
                            props.onPressDelete?.(item.id)
                        }}>
                            <IconButton icon={TrashIcon} />
                        </button>
                    </div>
                </div>
            </div>
        </>)}
    </>
}

export function ProductTableMobileSkeleton() {
    return <>{new Array(6).fill(0).map(() => <div className="mb-2 w-full rounded-md bg-white p-4">
        <div className="flex items-center justify-between border-b border-gray-100 pb-8">
            <div className="flex items-center">
                <div className="mr-2 h-8 w-8 rounded-full bg-gray-100"></div>
                <div className="h-6 w-16 rounded bg-gray-100"></div>
            </div>
            <div className="h-6 w-16 rounded bg-gray-100"></div>
        </div>
        <div className="flex w-full items-center justify-between pt-4">
            <div>
                <div className="h-6 w-16 rounded bg-gray-100"></div>
                <div className="mt-2 h-6 w-24 rounded bg-gray-100"></div>
            </div>
            <div className="flex justify-end gap-2">
                <div className="h-10 w-10 rounded bg-gray-100"></div>
                <div className="h-10 w-10 rounded bg-gray-100"></div>
            </div>
        </div>
    </div>)}
    </>
}