import Cookies from 'js-cookie';
import { cookiesKey, event, eventKeys } from '../utils/constants';
import { Navigate, Outlet, useNavigate } from 'react-router-dom';
import { useEffect } from 'react';

export default function PrivateRoute() {
    const navigate = useNavigate()
    useEffect(() => {
        const navLogin = () => {
            navigate('/login')
        }
        event.addListener(eventKeys.cookiesCleared, navLogin)
        return () => { event.removeAllListeners(eventKeys.cookiesCleared) }
    }, [navigate])
    const isLoggedIn = Cookies.get(cookiesKey.accessToken);
    return !isLoggedIn ? <Navigate to={'/login'} /> : <Outlet />
}