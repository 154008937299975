export const api = {
    auth: {
        login: '/auth/v1/login',
        refreshToken: '/auth/v1/refresh-token',
    },
    locksmith: {
        list: '/locksmith/v1/list',
        delete: '/locksmith/v1/delete/',
        create: '/locksmith/v1/create',
        edit: '/locksmith/v1/edit/',
        detail: '/locksmith/v1/detail/',
        stats: '/locksmith/v1/stats',

    }
}