export enum RoleType {
    ADMIN = 'admin',
    LOCKSMITH = 'locksmith'
}

export enum OrderStatus {
    Processing = 'Processing',
    Delivered = 'Delivered',
    OutForDelivery = 'Out for delivery'
}

export enum DeliveryType {
    Regular = "Regular",
    Fast = "Fast"
}

export type HttpMethods = 'GET' | 'POST' | 'PUT' | 'DELETE' | 'PATCH'