import React from "react"

interface IconButtonProps {
    icon: React.ElementType
}
export default function IconButton(props: IconButtonProps): React.JSX.Element {
    const Icon = props.icon
    return <div className="rounded-md border p-2 hover:bg-gray-100">
        <Icon className="w-5" />
    </div>
}