import clsx from 'clsx';
import { generatePagination } from '../utils/helpers';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/outline';

interface PaginationProps {
    totalPages: number;
    currentPage: number;
    onChangePage?: (page: number) => void
}

export default function Pagination(props: PaginationProps) {
    const allPages = generatePagination(props.currentPage, props.totalPages);
    const onPressLeft = () => props.onChangePage?.(props.currentPage - 1)
    const onPressRight = () => props.onChangePage?.(props.currentPage + 1)
    return (
        <>
            <div className="inline-flex">
                <PaginationArrow
                    direction="left"
                    onClick={onPressLeft}
                    isDisabled={props.currentPage <= 1}
                />
                <div className="flex -space-x-px">
                    {allPages.map((page, index) => {
                        let position: 'first' | 'last' | 'single' | 'middle' | undefined;

                        if (index === 0) position = 'first';
                        if (index === allPages.length - 1) position = 'last';
                        if (allPages.length === 1) position = 'single';
                        if (page === '...') position = 'middle';

                        return (
                            <PaginationNumber
                                key={page}
                                page={page}
                                position={position}
                                onClick={props.onChangePage}
                                isActive={props.currentPage === page}
                            />
                        );
                    })}
                </div>
                <PaginationArrow
                    direction="right"
                    onClick={onPressRight}
                    isDisabled={props.currentPage >= props.totalPages}
                />
            </div>
        </>
    );
}

function PaginationNumber({
    page,
    isActive,
    position,
    onClick
}: {
    page: number | string;
    position?: 'first' | 'last' | 'middle' | 'single';
    isActive: boolean;
    onClick?: (page: number) => void

}) {
    const className = clsx(
        'cursor-pointer flex h-10 w-10 items-center justify-center text-sm border',
        {
            'rounded-l-md': position === 'first' || position === 'single',
            'rounded-r-md': position === 'last' || position === 'single',
            'z-10 bg-blue-600 border-blue-600 text-white': isActive,
            'hover:bg-gray-100': !isActive && position !== 'middle',
            'text-gray-300': position === 'middle',
        },
    );

    return isActive || position === 'middle' ? (
        <div className={className}>{page}</div>
    ) : (
        <div className={className} onClick={() => onClick && onClick(Number(page))}>
            {page}
        </div>
    );
}

function PaginationArrow({
    direction,
    isDisabled,
    onClick
}: {
    direction: 'left' | 'right';
    isDisabled?: boolean;
    onClick?: () => void
}) {
    const className = clsx(
        'cursor-pointer flex h-10 w-10 items-center justify-center rounded-md border',
        {
            'cursor-default pointer-events-none text-gray-300': isDisabled,
            'hover:bg-gray-100': !isDisabled,
            'mr-2 md:mr-4': direction === 'left',
            'ml-2 md:ml-4': direction === 'right',
        },
    );

    const icon =
        direction === 'left' ? (
            <ChevronLeftIcon className="w-4" />
        ) : (
            <ChevronRightIcon className="w-4" />
        );

    return isDisabled ? (
        <div className={className}>{icon}</div>
    ) : (
        <div className={className} onClick={onClick}>
            {icon}
        </div>
    );
}
