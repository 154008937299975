import './App.css';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import PrivateRoute from './routes/private';
import PublicRoute from './routes/public';
import Main from './pages/private/main';
import NotFound from './pages/public/not-found';
import { ToastContainer } from 'react-toastify';
import Login from './pages/public/login';
import 'react-toastify/dist/ReactToastify.css';
import Dashboard from './pages/private/dashboard';
import Locksmiths from './pages/private/locksmiths';
import CreateLocksmith from './pages/private/create-locksmith';
import EditLocksmith from './pages/private/edit-locksmith';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path='/login' element={<PublicRoute />}>
          <Route path='/login' element={<Login />} />
        </Route>
        <Route path='/' element={<PrivateRoute />}>
          <Route path='/' element={<Main />}>
            <Route path='/' element={<Dashboard />} />
            <Route path='/locksmiths' element={<Locksmiths />} />
            <Route path='/locksmiths/create' element={<CreateLocksmith />} />
            <Route path='/locksmiths/edit/:id' element={<EditLocksmith />} />
          </Route>
        </Route>
        <Route path='/404' element={<NotFound />} />
        <Route path='*' element={<Navigate to={'/404'} replace />} />
      </Routes>
      <ToastContainer />
    </BrowserRouter>
  );
}

export default App;
