import IconButton from "../../components/icon-button";
import PaginationTable from "../../components/pagination-table";
import { PageMeta, PaginationTableColumns, LocksmithList } from "../../utils/types";
import { useEffect, useMemo, useRef, useState } from "react";
import { request } from "../../utils/request";
import { api } from "../../utils/api";
import Pagination from "../../components/pagination-arrow";
import LocksmithTableMobile, { ProductTableMobileSkeleton } from "../../components/locksmith-table-mobile";
import Search from "../../components/search";
import { IconTitleButton } from "../../components/icon-title-button";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import ConfirmationDialog, { ConfirmationDialogRef } from "../../components/confirmation-dialog";
import { ifNaNReturnDefault } from "../../utils/helpers";
import { NoSymbolIcon, PencilIcon, PlusIcon, TrashIcon } from "@heroicons/react/24/outline";
import parsePhoneNumberFromString from "libphonenumber-js";


export default function Locksmiths() {
    const modalRef = useRef<ConfirmationDialogRef>(null);
    const selectedId = useRef<number>();
    const [searchParams, setSearchParams] = useSearchParams();
    const page = ifNaNReturnDefault(searchParams.get('page'), 1);
    const search = searchParams.get('search') ?? '';
    const onPressDeleteIcon = (id: number) => {
        modalRef.current?.open()
        selectedId.current = id
    }
    const navigate = useNavigate();
    const columns: PaginationTableColumns[] = useMemo(() => [
        {
            header: 'Name',
            identifier: ['full_name']
        },
        {
            header: 'Email',
            identifier: ['email'],
        },
        {
            header: 'Phone No.',
            identifier: ['locksmith', 'contact_number'],
            cell(row) {
                return <p>{parsePhoneNumberFromString(row.locksmith.contact_number)?.formatInternational()}</p>
            },
        },
        {
            header: '',
            identifier: [''],
            cell(row) {
                return <div className="flex justify-center gap-3">
                    <div className="cursor-pointer" onClick={(e) => {
                        e.stopPropagation()
                        navigate('/locksmiths/edit/' + row.id)
                    }}>
                        <IconButton icon={PencilIcon} />
                    </div>
                    <div className="cursor-pointer" onClick={(e) => {
                        e.stopPropagation()
                        onPressDeleteIcon(row.id)
                    }}>
                        <IconButton icon={TrashIcon} />
                    </div>
                </div>
            },
        }], [navigate])

    const [data, setData] = useState<LocksmithList[]>([]);
    const [meta, setMeta] = useState<PageMeta>();
    const [loading, setLoading] = useState(true);
    const [refresh, setRefresh] = useState(Date.now());
    const [deleteLoading, setDeleteLoading] = useState(false);

    useEffect(() => {
        setLoading(true)
        fetchLocksmiths(page, search).then((res) => {
            setData(res.list)
            setMeta(res.meta)
            setLoading(false)
        })
    }, [page, search, refresh])

    const onPressDelete = async () => {
        setDeleteLoading(true)
        try {
            await request('DELETE', api.locksmith.delete + selectedId.current);
            setDeleteLoading(false)
            setRefresh(Date.now())
            selectedId.current = undefined;
            modalRef.current?.close()
        } catch (error) {

        }
    }

    const onChangePage = (page: number) => {
        searchParams.set('page', page.toString())
        setSearchParams(searchParams)
    }

    const onChangeSearch = (q: string) => {
        if (!q) searchParams.delete('search');
        else searchParams.set('search', q);
        searchParams.set('page', '1');
        setSearchParams(searchParams)
    }

    return <>
        <div className="w-full md:p-0 p-3 mb-10">
            <div className="flex w-full items-center justify-between">
                <h1 className={`text-2xl`}>Locksmiths</h1>
            </div>
            <div className="mt-4 mr-6 ml-6 flex items-center justify-between gap-2 md:mt-8">
                <Search defaultValue={search} onChange={onChangeSearch} placeholder="Search locksmith..." />
                <Link to={'/locksmiths/create'}>
                    <IconTitleButton icon={PlusIcon} title="Create locksmith" />
                </Link>
            </div>
            <div className="mt-5 flex w-full">
                <PaginationTable
                    data={data}
                    mobileShimmer={<ProductTableMobileSkeleton />}
                    mobileView={<LocksmithTableMobile data={data} loading={loading} onPressDelete={onPressDeleteIcon} />}
                    loading={loading}
                    columns={columns}
                // onClickRow={onPressRow}
                />
            </div>
            {!!meta && <div className="mt-5 flex w-full justify-center">
                <Pagination totalPages={meta.totalPages} currentPage={page}
                    onChangePage={onChangePage}
                />
            </div>}
        </div>
        <ConfirmationDialog loading={deleteLoading} onSubmit={onPressDelete} buttonTitle="Delete" description="Are you sure you want to delete?" icon={NoSymbolIcon} themeColor="red" title="Delete Locksmith" ref={modalRef} />
    </>
}

const fetchLocksmiths = async (page: number, search: string) => {
    try {
        const response = await request('GET', api.locksmith.list + `?pagination=yes&limit=6&page=${page}${search ? `&q=${search}` : ''}`)
        return response.data
    } catch (error) {
        throw error;
    }
}