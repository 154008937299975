import LocksmithForm from "../../components/locksmith-form";

export default function CreateLocksmith() {
    return <div className="w-full md:p-0 p-3">
        <div className="flex w-full items-center justify-between">
            <h1 className={`text-2xl`}>Create Locksmith</h1>
        </div>
        <div className="mt-5 pl-5 pr-5">
            <LocksmithForm />
        </div>
    </div>
}