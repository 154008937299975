import Cookies from "js-cookie";
import { api } from "../utils/api";
import { request } from "../utils/request";
import { LoginFormFieldType } from "../utils/types";
import { cookiesKey } from "../utils/constants";
import axios from "axios";
import { RoleType } from "../utils/enum";
import { getUniqueBrowserId } from "../utils/helpers";



export const signIn = async (form: LoginFormFieldType) => {
    try {
        const data = {
            model_no: "n/a",
            os: "android",
            app_type: "browser",
            brand: "n/a",
            device_version: "n/a",
            strategy: "local",
            passport_type: "email",
            serial_number: await getUniqueBrowserId(),
            version_number: "n/a",
            email: form.email,
            password: form.password,
        }
        const response = await request('POST', api.auth.login, data);
        if (response.data.data.role === RoleType.ADMIN) {
            Cookies.set(cookiesKey.accessToken, response.data.data.access_token)
            Cookies.set(cookiesKey.refreshToken, response.data.data.refresh_token)
        }
        return { data: response.data.data, isOk: true, message: response.data.message };
    } catch (error: any) {
        if (axios.isAxiosError(error)) {
            return { isOk: false, message: error.response?.data.message };
        }
        else return { isOk: false, message: error.message };
    }
}

export const signOut = async () => {
    Cookies.remove(cookiesKey.accessToken)
    Cookies.remove(cookiesKey.refreshToken)
}