import { getValueFromPath } from "../utils/helpers";
import { PaginationTableColumns } from "../utils/types";

interface PaginationTableProps {
    columns: PaginationTableColumns[]
    data?: any[];
    loading?: boolean
    mobileView?: React.ReactNode;
    mobileShimmer?: React.ReactNode;
    onClickRow?: (e: React.MouseEvent<HTMLTableRowElement, MouseEvent>, row: any) => void
}

export default function PaginationTable(props: PaginationTableProps) {
    return props.loading ? <TableShimmer {...props} />
        : <div className="mt-6 flow-root w-full pl-5 pr-5">
            <div className="inline-block min-w-full align-middle">
                <div className="rounded-lg bg-gray-50 p-2 md:pt-0">
                    <div className="md:hidden">
                        {props.mobileView}
                    </div>
                    <table className="hidden min-w-full text-gray-900 md:table">
                        <thead className="rounded-lg text-left text-sm font-normal">
                            <tr>
                                {props.columns.map((item) => <th scope="col" className="px-4 py-5 font-medium sm:pl-6" key={item.identifier.toLocaleString() + Date.now()}>
                                    {item.header}
                                </th>)}
                            </tr>
                        </thead>
                        <tbody className="bg-white">
                            {props?.data?.map?.((row, index) => <>
                                <tr
                                    onClick={(e) => props.onClickRow?.(e, row)}
                                    className="cursor-pointer w-full border-b py-3 text-sm last-of-type:border-none [&:first-child>td:first-child]:rounded-tl-lg [&:first-child>td:last-child]:rounded-tr-lg [&:last-child>td:first-child]:rounded-bl-lg [&:last-child>td:last-child]:rounded-br-lg"
                                    key={index + Date.now()}>
                                    {props.columns.map((column, index) => <>
                                        <td className="whitespace-nowrap px-3 py-3">
                                            {column.cell ? column.cell(row) : <>{getValueFromPath(row, column.identifier)}</>}
                                        </td>
                                    </>)}
                                </tr>
                            </>)}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
}

function TableShimmer(props: PaginationTableProps) {
    return <div className="mt-6 flow-root w-full pl-5 pr-5">
        <div className="inline-block min-w-full align-middle">
            <div className="rounded-lg bg-gray-50 p-2 md:pt-0">
                <div className="md:hidden">
                    {props.mobileShimmer}
                </div>
                <table className="hidden min-w-full text-gray-900 md:table">
                    <thead className="rounded-lg text-left text-sm font-normal">
                        <tr>
                            {props.columns.map((item) => <th scope="col" className="px-4 py-5 font-medium sm:pl-6" key={item.identifier.toLocaleString() + Date.now()}>
                                {item.header}
                            </th>)}
                        </tr>
                    </thead>
                    <tbody className="bg-white">
                        {new Array(6).fill(0).map(() => <>
                            <tr key={Date.now()} className="w-full border-b border-gray-100 last-of-type:border-none [&:first-child>td:first-child]:rounded-tl-lg [&:first-child>td:last-child]:rounded-tr-lg [&:last-child>td:first-child]:rounded-bl-lg [&:last-child>td:last-child]:rounded-br-lg">
                                {props.columns.map((item) => <>
                                    <td className="whitespace-nowrap px-3 py-3">
                                        <div className="h-6 w-16 rounded bg-gray-100"></div>
                                    </td>
                                </>)}
                            </tr>
                        </>)}
                    </tbody>
                </table>
            </div>
        </div>
    </div>
}