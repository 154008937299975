import axios, { InternalAxiosRequestConfig } from 'axios';
import { HttpMethods } from '../utils/enum';
import { api } from './api';
import { getAccessToken, getRefreshToken } from './helpers';
import Cookies from 'js-cookie';
import { cookiesKey, event, eventKeys } from './constants';

type AxiosConfigType = (InternalAxiosRequestConfig<any> & { _retry: boolean }) | undefined;

export const env = 1;
export const getURL = (env: number) => {
    if (env === 1) return 'https://locksmith-api.magibaindia.in'
    else return 'http://localhost:3000';
};

const instance = axios.create({
    baseURL: getURL(env),
});

let isRefreshing = false;
let refreshSubscribers: AxiosConfigType[] = [];
const onRefreshed = (token: string) => {
    refreshSubscribers.map((cb: any) => cb(token));
};
const subscribeTokenRefresh = (cb: any) => {
    refreshSubscribers.push(cb);
};

instance.interceptors.response.use(
    config => {
        return config;
    },
    async error => {
        if (error) {
            if (axios.isAxiosError(error)) {
                const originalRequest = error.config as AxiosConfigType;
                if (!error.response) {
                    return Promise.reject('Network Error');
                } else if (error.response.status === 401 && !originalRequest?._retry) {
                    if (originalRequest) originalRequest._retry = true;
                    let retryOrigReq;
                    try {
                        if (originalRequest) {
                            retryOrigReq = new Promise((resolve, reject) => {
                                subscribeTokenRefresh((token: string) => {
                                    originalRequest.headers['Authorization'] = 'Bearer ' + token;
                                    return resolve(instance(originalRequest));
                                });
                            });
                        }
                        if (!isRefreshing) {
                            isRefreshing = true;
                            const refreshToken = getRefreshToken();
                            console.log("🚀 ~ refreshToken:", refreshToken)
                            const response = await axios({
                                method: 'PUT',
                                baseURL: getURL(env),
                                url: api.auth.refreshToken,
                                data: {
                                    refresh_token: refreshToken,
                                },
                            });
                            console.log("🚀 ~ refreshToken:", response)
                            Cookies.set(cookiesKey.accessToken, response?.data?.data?.access_token, { secure: true });
                            Cookies.set(cookiesKey.refreshToken, response?.data?.data?.refresh_token, { secure: true });
                            isRefreshing = false;
                            onRefreshed(response?.data?.data?.access_token);
                        }
                        return retryOrigReq;
                    } catch (error: any) {
                        // crashlytics().recordError(error)
                        if (axios.isAxiosError(error)) {
                            if (error.response?.status === 400) {
                                Cookies.remove(cookiesKey.accessToken)
                                Cookies.remove(cookiesKey.refreshToken)
                                event.emit(eventKeys.cookiesCleared)
                                return Promise.reject(error);
                            }
                        }
                    }
                } else {
                    return Promise.reject(error);
                }
            } else {
                return Promise.reject(error);
            }
        }
    },
);

export const request = async (method: HttpMethods = 'GET', url: string, data?: any) => {
    try {
        const response = await instance({
            method,
            url,
            headers: {
                Accept: 'application/json',
                Authorization: getAccessToken(),
            },
            data: method ? (method === 'GET' || method === 'DELETE' ? undefined : data) : undefined,
        });

        return response;
    } catch (error: any) {
        throw error;
    }
};
