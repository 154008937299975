import { useEffect, useMemo, useState } from "react"
import { useCurrentTime } from "../utils/hooks"
import { request } from "../utils/request"
import { api } from "../utils/api"

export default function DashboardStats() {
    const time = useCurrentTime()
    const [dashboardStats, setDashboardStats] = useState({ count: '..', checked_in_count: '...' })
    useEffect(() => {
        fetchDashboardStats().then(setDashboardStats)
    }, [])
    const stats = useMemo(() => ([
        { name: 'Total Locksmiths', stat: dashboardStats.count },
        { name: 'Checked In Locksmiths', stat: dashboardStats.checked_in_count },
        { name: 'Clock', stat: time },
    ]), [time, dashboardStats])
    return (
        <div>
            <h3 className="text-base font-semibold text-gray-900">Stats</h3>
            <dl className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-3">
                {stats.map((item) => (
                    <div key={item.name} className="overflow-hidden rounded-lg bg-white px-4 py-5 shadow sm:p-6">
                        <dt className="truncate text-sm font-medium text-gray-500">{item.name}</dt>
                        <dd className="mt-1 text-3xl font-semibold tracking-tight text-gray-900">{item.stat}</dd>
                    </div>
                ))}
            </dl>
        </div>
    )
}


const fetchDashboardStats = async () => {
    const res = await request('GET', api.locksmith.stats);
    return res.data
}