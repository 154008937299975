interface IconTitleButtonProps {
    icon: React.ElementType
    title: string
    onClick?: () => void
}

export function IconTitleButton(props: IconTitleButtonProps) {
    return (
        <div onClick={props.onClick} className="cursor-pointer flex h-10 items-center rounded-lg bg-blue-600 px-4 text-sm font-medium text-white transition-colors hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600">
            <span className="hidden md:block">{props.title}</span>{' '}
            <props.icon className="h-5 md:ml-4" />
        </div>
    );
}