import EventEmitter from "events"

export const cookiesKey = {
    accessToken: 'accessToken',
    refreshToken: 'refreshToken',
}

export const event = new EventEmitter()
export const eventKeys = {
    cookiesCleared: 'cookiesCleared'
}