import Cookies from "js-cookie"
import { cookiesKey } from "./constants"

export const getAccessToken = () => {
    return `Bearer ${Cookies.get(cookiesKey.accessToken)}`
}

export const getRefreshToken = () => {
    return Cookies.get(cookiesKey.refreshToken)
}

export const generatePagination = (currentPage: number, totalPages: number) => {
    // If the total number of pages is 7 or less,
    // display all pages without any ellipsis.
    if (totalPages <= 7) {
        return Array.from({ length: totalPages }, (_, i) => i + 1);
    }

    // If the current page is among the first 3 pages,
    // show the first 3, an ellipsis, and the last 2 pages.
    if (currentPage <= 3) {
        return [1, 2, 3, '...', totalPages - 1, totalPages];
    }

    // If the current page is among the last 3 pages,
    // show the first 2, an ellipsis, and the last 3 pages.
    if (currentPage >= totalPages - 2) {
        return [1, 2, '...', totalPages - 2, totalPages - 1, totalPages];
    }

    // If the current page is somewhere in the middle,
    // show the first page, an ellipsis, the current page and its neighbors,
    // another ellipsis, and the last page.
    return [
        1,
        '...',
        currentPage - 1,
        currentPage,
        currentPage + 1,
        '...',
        totalPages,
    ];
};

export const ifNaNReturnDefault = (num: any, defaultNum: number) => {
    const convertedNum = !num ? NaN : Number(num)
    if (!Number.isNaN(convertedNum)) return convertedNum;
    else return defaultNum;
}


export async function getUniqueBrowserId() {
    const deviceInfo = [
        navigator.userAgent, // Browser and OS
        navigator.language, // Browser language
        `${window.screen.width}x${window.screen.height}`, // Screen resolution
        window.screen.colorDepth, // Color depth
        new Date().getTimezoneOffset(), // Timezone offset
        navigator.hardwareConcurrency, // Number of logical processors
        getWebGLRenderer() // WebGL Renderer
    ].join("|");

    // Hash the information to generate a unique ID
    const encoder = new TextEncoder();
    const data = encoder.encode(deviceInfo);
    const hashBuffer = await crypto.subtle.digest("SHA-256", data);
    const hashArray = Array.from(new Uint8Array(hashBuffer));
    const uniqueId = hashArray.map(b => b.toString(16).padStart(2, "0")).join("");

    return uniqueId;
}

function getWebGLRenderer() {
    try {
        const canvas = document.createElement("canvas");
        const gl = canvas.getContext("webgl") || canvas.getContext("experimental-webgl");
        if (gl && gl instanceof WebGLRenderingContext) {
            const debugInfo = gl.getExtension("WEBGL_debug_renderer_info");
            return debugInfo ? gl.getParameter(debugInfo.UNMASKED_RENDERER_WEBGL) : "Unknown Renderer";
        }
    } catch (e) {
        return "Unknown Renderer";
    }
    return "Unknown Renderer";
}


export function getCurrentTime() {
    const now = new Date();

    let hours = now.getHours();
    const minutes = now.getMinutes();
    const ampm = hours >= 12 ? 'PM' : 'AM';

    hours = hours % 12 || 12; // Convert 24-hour time to 12-hour format, 0 becomes 12.
    const formattedMinutes = minutes.toString().padStart(2, '0'); // Ensure two digits.

    return `${hours}:${formattedMinutes} ${ampm}`;
}

export function getValueFromPath(obj: object, keys: string[]) {
    return keys.reduce((acc: any, key) => acc && acc[key], obj);
}
