import { LocksmithFieldType } from "../utils/types";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Form, Field, Formik, FieldAttributes, FormikProps } from "formik";
import * as Yup from 'yup';
import clsx from "clsx";
import { request } from "../utils/request";
import { api } from "../utils/api";
import { Button } from "./button";
import Spinner from "./spinner";
import { toast } from "react-toastify";
import axios from "axios";
import { AtSymbolIcon, UserIcon } from "@heroicons/react/24/outline";
import { isValidNumber } from "libphonenumber-js";

interface LocksmithFormProps {
    loading?: boolean
    defaultValues?: LocksmithFieldType
    id?: number
}

export default function LocksmithForm(props: LocksmithFormProps) {
    const isEdit = !!props.defaultValues;
    const [initialValues, setInitialValues] = useState<LocksmithFieldType>({
        full_name: '',
        email: '',
        password: '',
        contact_number: ''
    });
    useEffect(() => {
        if (props.defaultValues) {
            setInitialValues(props.defaultValues);
        }
    }, [props.defaultValues])
    const navigate = useNavigate();
    return <div className={clsx({ 'opacity-20 pointer-events-none': props.loading })}>
        <Formik
            enableReinitialize
            validateOnChange
            validateOnBlur={false}
            initialValues={initialValues}
            validationSchema={isEdit ? editProductValidationSchema : createProductValidationSchema}
            onSubmit={async (values, actions) => {
                if (!isEdit) await createLocksmith(values);
                else await editLocksmith(props.id ?? 0, values);
                navigate('/locksmiths', { replace: true })
            }}
        >
            {(helpers) => (<>
                <ProductFormikForm isEdit={isEdit} helpers={helpers} />
            </>)}

        </Formik>
    </div>
}

interface LocksmithFormikFormProps {
    helpers: FormikProps<LocksmithFieldType>;
    isEdit: boolean
}
function ProductFormikForm({ helpers, isEdit }: LocksmithFormikFormProps) {
    return <Form>
        <LocksmithField
            id="full_name"
            name="full_name"
            icon={UserIcon}
            label="Full Name"
            placeholder="John Doe"
            isError={!!helpers.errors.full_name}
        />
        <LocksmithField
            id="email"
            name="email"
            icon={AtSymbolIcon}
            label="Email"
            placeholder="john@doe.com"
            isError={!!helpers.errors.email}
        />
        <LocksmithField
            id="password"
            name="password"
            icon={UserIcon}
            label={"Password" + (isEdit ? ' (Leave empty to keep unchanged)' : '')}
            placeholder="any random password, strong"
            isError={!!helpers.errors.password}
        />
        <LocksmithField
            id="contact_number"
            name="contact_number"
            icon={UserIcon}
            label="Contact Number"
            placeholder="+12162222222"
            isError={!!helpers.errors.contact_number}
        />
        <div className="mt-6 flex justify-end gap-4 mb-10">
            {helpers.isSubmitting
                ? <Spinner /> : <>
                    <Link
                        to="/locksmiths"
                        className="flex h-10 items-center rounded-lg bg-gray-100 px-4 text-sm font-medium text-gray-600 transition-colors hover:bg-gray-200"
                    >
                        Cancel
                    </Link>
                    <Button type="submit">{isEdit ? 'Edit' : 'Create'} Locksmith</Button>
                </>}
        </div>
    </Form>
}

const createProductValidationSchema = Yup.object({
    full_name: Yup.string().required('Required!'),
    email: Yup.string().email().required('Required!'),
    password: Yup.string().required('Required!'),
    contact_number: Yup.string()
        .required('Required!')
        .test('is-valid-phone', 'Invalid phone number', (value) => {
            return value ? isValidNumber(value) : false;
        })
});

const editProductValidationSchema = Yup.object({
    full_name: Yup.string().required('Required!'),
    email: Yup.string().required('Required!'),
    password: Yup.string(),
    contact_number: Yup.string()
        .required('Required!')
        .test('is-valid-phone', 'Invalid phone number', (value) => {
            return value ? isValidNumber(value) : false;
        })
});

interface LocksmithFieldProps extends FieldAttributes<any> {
    label: string
    isError: boolean
    icon: React.ElementType
}

function LocksmithField(props: LocksmithFieldProps) {
    return <div className="mb-4">
        <label htmlFor={props.name} className={
            clsx("text-sm font-medium leading-6",
                { 'text-red-600': props.isError },
                { 'text-gray-900': !props.isError })
        }>
            {props.label}<label className="text-sm font-large leading-6 text-red-500">{props.isError && '*'}</label>
        </label>
        <div className="relative mt-2 rounded-md">
            <div className="relative">
                <Field
                    {...props}
                    className="peer block w-full rounded-md border border-gray-200 py-2 pl-10 text-sm outline-2 placeholder:text-gray-500"
                />
                <props.icon className="pointer-events-none absolute left-3 top-1/2 h-[18px] w-[18px] -translate-y-1/2 text-gray-500 peer-focus:text-gray-900" />
            </div>
        </div>
    </div>
}



const createLocksmith = async (values: LocksmithFieldType) => {
    try {
        const data = createProductValidationSchema.cast(values) as any;
        await request('POST', api.locksmith.create, data);
    } catch (error: any) {
        if (axios.isAxiosError(error)) {
            toast(String(error.response?.data?.message), { type: 'error' })
        }
        else {
            toast(error.message, { type: 'error' })
        }
        throw error
    }
}

const editLocksmith = async (id: number, values: LocksmithFieldType) => {
    try {
        const data = editProductValidationSchema.cast(values) as any;
        if (!data.password) delete data.password
        await request('PUT', api.locksmith.edit + id, data);
    } catch (error: any) {
        if (axios.isAxiosError(error)) {
            toast(String(error.response?.data?.message), { type: 'error' })
        }
        else {
            toast(error.message, { type: 'error' })
        }
        throw error
    }
}