import { useEffect, useState } from "react";
import { getCurrentTime } from "./helpers";

export const useDebounceEffect = (callback: () => void, time: number, deps: Array<any>) => {
    useEffect(() => {
        const fn = setTimeout(callback, time);
        return () => clearTimeout(fn);
        // eslint-disable-next-line
    }, [...deps]);
};

export const useCurrentTime = () => {
    const [time, setTime] = useState(getCurrentTime());

    useEffect(() => {
        const interval = setInterval(() => {
            setTime(getCurrentTime())
        }, 10000)
        return () => clearInterval(interval)
    }, [])

    return time
}